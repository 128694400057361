import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

function UploadPage() {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadError, setUploadError] = useState("");
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const navigate = useNavigate();

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        if (files.length > 10) {
            setUploadError("You can upload a maximum of 10 images.");
            return;
        }
        setSelectedFiles(files);
        setUploadError("");  // Reset error when new files are selected
        setUploadSuccess(false);  // Reset success message when new files are selected
    };

    const handleUpload = async () => {
        setUploadError("");  // Clear previous errors
        setUploadSuccess(false);  // Reset success state

        if (selectedFiles.length === 0) {
            setUploadError("Please select files to upload.");
            return;
        }

        const formData = new FormData();
        selectedFiles.forEach((file) => {
            formData.append("file", file);
        });

        const uploadUrl = `${process.env.REACT_APP_BASE_URL}/api/upload`;

        try {
            const response = await fetch(uploadUrl, {
                method: "POST",
                body: formData,
                credentials: "include"
            });

            if (response.ok) {
                setUploadSuccess(true);
                alert("Files uploaded successfully!");
            } else {
                const errorMessage = await response.text();
                setUploadError(`Failed to upload files: ${errorMessage}`);
            }
        } catch (error) {
            console.error("Error uploading files:", error);
            setUploadError("An unexpected error occurred while uploading the files. Please try again later.");
        }
    };


    const handleLogout = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/logout`, {
                method: "POST",
                credentials: "include",
            });
            if (!response.ok) {
                throw new Error("Failed to log out");
            }
            navigate("/");
        } catch (error) {
            console.error("Error during logout:", error);
            alert("An error occurred while logging out. Please try again.");
        }
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-pink-50">
            <h1 className="text-4xl font-bold text-pink-700 mb-8">Upload Your Photos</h1>

            {uploadError && <p className="text-red-500 mb-4">{uploadError}</p>}
            {uploadSuccess && <p className="text-green-500 mb-4">Files uploaded successfully!</p>}

            <input
                type="file"
                multiple
                accept="image/*"
                onChange={handleFileChange}
                className="mb-4 p-2 border border-gray-300 rounded"
            />

            <button
                onClick={handleUpload}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mb-4"
            >
                Upload Photos
            </button>

            <button
                onClick={() => navigate("/gallery")}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 mb-4"
            >
                Go to Gallery
            </button>

            <button
                onClick={handleLogout}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
            >
                Logout
            </button>
        </div>
    );
}

export default UploadPage;
