import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // null indicates loading state
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Function to check authentication status
    const checkAuthStatus = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/auth/check`, {
          method: "GET",
          credentials: "include",
        });

        if (response.ok) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error("Error checking authentication status:", error);
        setIsAuthenticated(false);
      } finally {
        setLoading(false);
      }
    };

    checkAuthStatus();
  }, []);

  if (loading) {
    return (
        <div className="flex justify-center items-center h-screen">
      <div className="flex items-center justify-center space-x-2 animate-pulse">
        <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
        <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
        <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
      </div>
    </div>
    )
  }

  if (!isAuthenticated) {
    // If not authenticated, redirect to the home page
    return <Navigate to="/"/>;
  }

  // If authenticated, render the children (the protected component)
  return children;
};

export default ProtectedRoute;
