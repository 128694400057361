import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";

function GalleryPage() {
    const [photos, setPhotos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedPhoto, setSelectedPhoto] = useState(null); // State to handle the selected photo for the modal
    const [currentIndex, setCurrentIndex] = useState(0); // Track the index of the selected photo
    const [isPlaying, setIsPlaying] = useState(false); // Track slideshow state
    const [theme, setTheme] = useState('light'); // Track theme state
    const [page, setPage] = useState(1); // Current page number
    const [limit] = useState(20); // Number of images per page
    const [totalPages, setTotalPages] = useState(1); // Total number of pages
    const navigate = useNavigate();

    useEffect(() => {
        const galleryUrl = `${process.env.REACT_APP_BASE_URL}/api/images?page=${page}&limit=${limit}`;

        const fetchPhotos = async () => {
            try {
                const response = await fetch(galleryUrl);

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setPhotos(data.data.images);
                const totalImages = data.total || data.data.totalItems; // Ensure total count is correctly referenced
                setTotalPages(Math.ceil(totalImages / limit)); // Calculate total pages based on the total images and limit
                setLoading(false);
            } catch (error) {
                console.error("Error fetching photos:", error);
                setError(error.message);
                setLoading(false);
            }
        };

        fetchPhotos();
    }, [page, limit]);

    useEffect(() => {
        const handleKeydown = (event) => {
            if (event.key === "Escape") {
                setSelectedPhoto(null);
            } else if (event.key === "ArrowRight") {
                showNextPhoto();
            } else if (event.key === "ArrowLeft") {
                showPreviousPhoto();
            }
        };

        // Add event listener for keydown events
        document.addEventListener("keydown", handleKeydown);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener("keydown", handleKeydown);
        };
    }, [currentIndex, photos]);

    useEffect(() => {
        let interval;
        if (isPlaying) {
            interval = setInterval(() => {
                showNextPhoto();
            }, 3000); // Change slide every 3 seconds
        }
        return () => clearInterval(interval);
    }, [isPlaying, currentIndex]);

    const handleLogout = () => {
        try {
            navigate("/");
        } catch (error) {
            console.error("Error during logout:", error);
            alert("An error occurred while logging out. Please try again.");
        }
    };

    const handlePhotoClick = (index) => {
        setSelectedPhoto(photos[index]);
        setCurrentIndex(index);
    };

    const handleCloseModal = () => {
        setSelectedPhoto(null);
        setIsPlaying(false); // Stop slideshow when modal is closed
    };

    const showNextPhoto = () => {
        if (currentIndex < photos.length - 1) {
            const nextIndex = currentIndex + 1;
            setSelectedPhoto(photos[nextIndex]);
            setCurrentIndex(nextIndex);
        } else {
            setSelectedPhoto(photos[0]);
            setCurrentIndex(0);
        }
    };

    const showPreviousPhoto = () => {
        if (currentIndex > 0) {
            const prevIndex = currentIndex - 1;
            setSelectedPhoto(photos[prevIndex]);
            setCurrentIndex(prevIndex);
        } else {
            setSelectedPhoto(photos[photos.length - 1]);
            setCurrentIndex(photos.length - 1);
        }
    };

    const toggleSlideshow = () => {
        setIsPlaying(!isPlaying);
    };

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        document.documentElement.setAttribute('data-theme', newTheme);
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    // Gesture handling for swipe events
    useEffect(() => {
        const handleTouchStart = (e) => {
            const touchStartX = e.touches[0].clientX;
            const handleTouchMove = (e) => {
                const touchEndX = e.changedTouches[0].clientX;
                if (touchStartX - touchEndX > 50) {
                    showNextPhoto();
                } else if (touchStartX - touchEndX < -50) {
                    showPreviousPhoto();
                }
                document.removeEventListener("touchmove", handleTouchMove);
            };
            document.addEventListener("touchmove", handleTouchMove);
        };

        document.addEventListener("touchstart", handleTouchStart);

        return () => {
            document.removeEventListener("touchstart", handleTouchStart);
        };
    }, [currentIndex, photos]);

    const getPreviewImages = () => {
        const previewImages = [];
        const totalThumbnails = 5; // Total number of thumbnails to show (including current)
        const half = Math.floor(totalThumbnails / 2);

        for (let i = currentIndex - half; i <= currentIndex + half; i++) {
            if (i >= 0 && i < photos.length) {
                previewImages.push({
                    src: photos[i],
                    index: i,
                });
            }
        }
        return previewImages;
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-pink-50">
                <p className="text-lg animate-pulse">Loading photos...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center min-h-screen bg-pink-50">
                <p className="text-lg text-red-500">Error: {error}</p>
            </div>
        );
    }

    return (
        <div
            className={`flex flex-col items-center justify-center min-h-screen ${theme === 'dark' ? 'bg-gray-800' : 'bg-pink-50'}`}>
            <h1 className={`text-4xl font-bold mb-8 animate-fadeIn ${theme === 'dark' ? 'text-white' : 'text-pink-700'}`}>
                Wedding Gallery
            </h1>
            {photos.length > 0 ? (
                <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-2">
                    {photos.map((photo, index) => (
                        <div
                            key={index}
                            className={`border rounded-lg overflow-hidden shadow-lg transform hover:scale-105 transition-transform duration-300 cursor-pointer ${theme === 'dark' ? 'border-gray-600' : 'border-pink-200'}`}
                            style={{width: "100px", height: "100px"}}
                            onClick={() => handlePhotoClick(index)}
                        >
                            <img
                                src={photo}
                                alt={`Photo ${index + 1}`}
                                className="w-full h-full object-cover"
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <p className={`text-lg ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>No photos
                    available.</p>
            )}

            {/* Pagination Controls */}
            <div className="flex items-center justify-center space-x-4 mt-4">
                <button
                    onClick={() => handlePageChange(page - 1)}
                    className={`text-sm px-3 py-2 rounded ${theme === 'dark' ? 'bg-gray-600 text-white' : 'bg-pink-400 text-white'}`}
                    disabled={page === 1}
                >
                    Previous
                </button>
                <span className={`text-sm ${theme === 'dark' ? 'text-white' : 'text-gray-700'}`}>
          Page {page} of {totalPages}
        </span>
                <button
                    onClick={() => handlePageChange(page + 1)}
                    className={`text-sm px-3 py-2 rounded ${theme === 'dark' ? 'bg-gray-600 text-white' : 'bg-pink-400 text-white'}`}
                    disabled={page === totalPages}
                >
                    Next
                </button>
            </div>

            <button
                onClick={toggleTheme}
                className={`mt-4 text-sm px-3 py-2 rounded ${theme === 'dark' ? 'bg-gray-600 text-white' : 'bg-pink-400 text-white'}`}
            >
                Toggle {theme === 'dark' ? 'Light' : 'Dark'} Mode
            </button>
            <button
                onClick={handleLogout}
                className={`bg-pink-500 text-white px-4 py-2 rounded hover:bg-pink-600 mt-8 shadow-md transform hover:scale-105 transition-transform duration-300`}
            >
                Logout
            </button>

            {/* Modal */}
            {selectedPhoto && (
                <div className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-black bg-opacity-75">
                    <div className="relative max-w-3xl max-h-[90vh] flex items-center justify-center">
                        <img
                            src={selectedPhoto}
                            alt="Full size"
                            className="max-w-full max-h-full object-contain"
                        />
                        <button
                            onClick={handleCloseModal}
                            className="absolute top-2 right-2 text-white bg-red-500 rounded-full p-2 z-10"
                        >
                            Close
                        </button>
                        {/* Navigation Arrows */}
                        <button
                            onClick={showPreviousPhoto}
                            className="absolute left-2 text-white bg-gray-700 rounded-full p-2 z-10"
                            style={{transform: 'translateY(-50%)'}}
                        >
                            &#10094;
                        </button>
                        <button
                            onClick={showNextPhoto}
                            className="absolute right-2 text-white bg-gray-700 rounded-full p-2 z-10"
                            style={{transform: 'translateY(-50%)'}}
                        >
                            &#10095;
                        </button>
                        <button
                            onClick={toggleSlideshow}
                            className="absolute bottom-2 text-white bg-gray-700 rounded-full p-2 z-10"
                        >
                            {isPlaying ? 'Pause' : 'Play'}
                        </button>
                    </div>
                    {/* Thumbnail Preview */}
                    <div className="mt-4 flex justify-center space-x-4">
                        {getPreviewImages().map(({src, index}) => (
                            <div
                                key={index}
                                className={`cursor-pointer ${index === currentIndex ? 'border-pink-500' : 'border-gray-300'}`}
                                onClick={() => handlePhotoClick(index)}
                            >
                                <img
                                    src={src}
                                    alt={`Preview ${index + 1}`}
                                    className={`w-16 h-16 object-cover border-2 rounded-lg ${index === currentIndex ? 'w-20 h-20' : 'w-16 h-16'}`}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default GalleryPage;
