import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function HomePage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    setError("");

    const formData = new URLSearchParams();
    formData.append("username", username);
    formData.append("password", password);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData.toString(),
      });

      if (response.ok) {
        navigate("/upload");
      } else {
        const data = await response.json();
        setError(data.error || "Invalid username or password.");
      }
    } catch (err) {
      console.error("An error occurred during login:", err);
      setError("An unexpected error occurred. Please try again later.");
    }
  };

  return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-background px-4">
        <h1 className="text-4xl font-bold text-header mb-4 text-center">
          Welcome to the Wedding Gallery
        </h1>
        <p className="text-lg text-text mb-8 text-center">
          Please log in to upload and manage your photos.
        </p>

        <form onSubmit={handleLogin} className="bg-white p-6 rounded shadow-md w-full max-w-xs">
          {error && <p className="text-red-500 mb-4">{error}</p>}

          <div className="mb-4">
            <label className="block text-gray-700">Username</label>
            <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
                required
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Password</label>
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-2 border border-text rounded"
                required
            />
          </div>

          <button
              type="submit"
              className="bg-header text-white px-4 py-2 rounded hover:bg-text w-full"
          >
            Login
          </button>
        </form>
      </div>
  );
}

export default HomePage;
